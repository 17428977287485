import { Layout } from "antd"
import React from "react"
import { useLocation } from "react-router-dom"
import { styled } from "styled-components"

import SessionManager from "api/SessionManager"
import favicon from "assets/img/pixel-brand.svg"
import Alert from "components/Alert/Alert"
import { AutoNewsletterDeliveryAlert } from "components/Alert/AutoNewsletterDeliveryAlert"
import Meta from "components/Meta"
import Userlane from "components/Userlane/Userlane"
import { MiddleContainer, responsiveContainerStyle, theme } from "style"
import { useExceededClientsLimit } from "utils/useExceededClientsLimitData"
import usePrefix from "utils/usePrefix"

import { CookiePolicy } from "./CookiePolicy"
import { PageFooter } from "./PageFooter"
import { PageHeader } from "./PageHeader"

import DEVRoleBtn from "../__DEVRoleBtn"

const { Content } = Layout

type Props = {
  children: React.ReactNode
}
export const AppLayout = ({ children }: Props) => {
  const tGeneral = usePrefix("General")
  const tClients = usePrefix("Clients")
  const { pathname } = useLocation()
  const isAOPublisher = SessionManager.getIsAOPublisher()

  const { clientLimit, exceededClientsLimit } = useExceededClientsLimit()
  const session = SessionManager.getSession()

  return (
    <>
      <Meta title={tGeneral("app_name")} favicon={favicon} />
      <StyledLayout>
        <PageHeader />
        {exceededClientsLimit && (
          <Alert
            message={`${tClients("exceededClientsLimit")} ${tClients(
              "reduceClientsLimit",
              { clientLimit: clientLimit }
            )}`}
            type="error"
            textColor={theme.colors.error}
            bgColor={theme.colors.bgRed}
          />
        )}
        {pathname.includes("/news/ao/edit") && isAOPublisher && (
          <AutoNewsletterDeliveryAlert />
        )}

        <StyledContent>
          <MiddleContainer>{children}</MiddleContainer>
        </StyledContent>
        <PageFooter />
      </StyledLayout>

      <CookiePolicy />
      {session?.realm === "Public" && (
        <Userlane
          appId="ndgewnl3gr"
          user={{
            id: session?.borgClientId,
            borgUserId: session?.borgUserId,
            userRole: session?.userRole,
            realm: session?.realm,
            isFirstLogon: session?.isFirstLogon
          }}
        />
      )}
      {process.env.NODE_ENV === "development" && <DEVRoleBtn />}
    </>
  )
}

const StyledContent = styled(Content)`
  min-height: calc(100vh - 225px);
  margin: auto;
  padding-top: ${({ theme }) => theme.paddings.large};
  width: 100%;
  ${responsiveContainerStyle};
`

const StyledLayout = styled(Layout)`
  height: 100%;
  width: 100%;
  display: flex;
  margin: auto;
  background: ${({ theme }) => theme.colors.bgGrey};
  position: relative;

  .ant-layout-footer {
    background: ${({ theme }) => theme.colors.bgGrey};
  }
`